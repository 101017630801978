import {
  ITransactionCashFlowArticle,
  ITransactionOperationType,
} from "pages/bank-statements/types";
import { IBaseResponse } from "./api";
import { api } from "./api-new";

export async function getOperationTypes() {
  try {
    const response = await api.get<IBaseResponse<ITransactionOperationType[]>>(
      "directories/money_transactions/operation_types"
    );
    return response.data.data;
  } catch (error) {
    return null;
  }
}

export async function getCashFlowArticles() {
  try {
    const response = await api.get<IBaseResponse<ITransactionCashFlowArticle[]>>(
      "directories/money_transactions/cash_flow_articles"
    );
    return response.data.data;
  } catch (error) {
    return null;
  }
}
