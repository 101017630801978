import { IBaseResponse } from "./api";
import { api } from "./api-new";
import {
  ICommitment,
  IContragent,
  IMoneyTransaction,
  IPnLArticle,
  ITax,
  ITransactionCashFlowArticle,
  ITransactionOperationType,
  ITransactionPayType,
  ITransactionStatus,
  ITransactionType,
} from "pages/bank-statements/types";
import { onError } from "./schedulePlanning";

export interface IStatementsListMeta {
  current_page: number;
  from: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number;
}

export interface IGetBankStatementsListParams {
  page: number;
  per_page?: number;
  contragent_id?: number;
  //YYYY-MM-DD
  date_from?: string;
  date_to?: string;

  //По number, amount, contragent_inn
  search?: string;

  type?: any[];
  pay_type?: any[];
  operation_type?: any[];
  status?: any[];

  show?: boolean;
}

export async function getMoneyTransactions(params: IGetBankStatementsListParams) {
  try {
    const response = await api.post<
      IBaseResponse<{ data: IMoneyTransaction[]; meta: IStatementsListMeta }>
    >("money_transactions/list", params);
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

export async function getMoneyTransaction(transactionId: IMoneyTransaction["id"]) {
  try {
    const response = await api.post<IBaseResponse<IMoneyTransaction>>("money_transactions/get", {
      id: transactionId,
    });
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

export async function updateMoneyTransaction(body: Partial<IMoneyTransaction>) {
  try {
    const response = await api.post<IBaseResponse<IMoneyTransaction>>(
      "money_transactions/update",
      body
    );
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

export async function deleteMoneyTransaction(id: IMoneyTransaction["id"]) {
  try {
    const response = await api.post<IBaseResponse<IMoneyTransaction>>("money_transactions/delete", {
      id,
    });
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

export async function addStatementsFile(formData: FormData) {
  try {
    const response = await api.post<
      IBaseResponse<{ count: number; error: number; success: number; skipped: number }>
    >(`money_transactions/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data.data;
  } catch (e) {
    return onError(e);
  }
}

export async function getTransactionTypes() {
  try {
    const response = await api.get<IBaseResponse<ITransactionType[]>>(
      "directories/money_transactions/types"
    );
    console.log(response);

    return response.data.data;
  } catch (error) {
    return null;
  }
}

export async function getTransactionPaymentTypes() {
  try {
    const response = await api.get<IBaseResponse<ITransactionPayType[]>>(
      "directories/money_transactions/pay_types"
    );
    return response.data.data;
  } catch (error) {
    return null;
  }
}

export async function getTransactionOperationTypes() {
  try {
    const response = await api.get<IBaseResponse<ITransactionOperationType[]>>(
      "directories/money_transactions/operation_types"
    );
    return response.data.data;
  } catch (error) {
    return null;
  }
}

export async function getTransactionStatuses() {
  try {
    const response = await api.get<IBaseResponse<ITransactionStatus[]>>(
      "directories/money_transactions/statuses"
    );
    return response.data.data;
  } catch (error) {
    return null;
  }
}

export async function getTransactionCashFlowArticles() {
  try {
    const response = await api.get<IBaseResponse<ITransactionCashFlowArticle[]>>(
      "directories/money_transactions/cash_flow_articles"
    );
    return response.data.data;
  } catch (error) {
    return null;
  }
}

export interface IGetContragentsFilter {
  //По title и inn
  search?: string | null;
  //Дефолт 1
  page?: number | null;
  //Дефолт 100
  per_page?: number | null;
}

export interface IContragentMetaProps {
  current_page: number;
  from: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number;
}

export async function getTransactionContragents(params?: IGetContragentsFilter) {
  try {
    const response = await api.get<
      IBaseResponse<{ data: IContragent[]; meta: IContragentMetaProps }>
    >("directories/contragents/paginated", { params });
    return response.data.data;
  } catch (error) {
    return null;
  }
}

export async function getPnLArticles() {
  try {
    const response = await api.get<IBaseResponse<IPnLArticle[]>>(
      "directories/money_transactions/pnl_articles"
    );
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

export async function getCommitments() {
  try {
    const response = await api.get<IBaseResponse<ICommitment[]>>(
      "directories/money_transactions/commitments"
    );
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

export async function getTaxes() {
  try {
    const response = await api.get<IBaseResponse<ITax[]>>("directories/money_transactions/taxes");
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}
