import { observer } from "mobx-react-lite";
import { FC, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import SelectField from "ui-new/select";
import { DynamicFormFields } from "../../types";
import { useStore } from "store";

interface CommitmentFieldProps {
  allDisabled: boolean;
}

const CommitmentTypeFieldComponent: FC<CommitmentFieldProps> = ({ allDisabled }) => {
  const {
    RootStore: {
      EditMoneyTransactionStore: { formFields, commitments },
    },
  } = useStore();
  const { control, watch } = useFormContext<{
    [DynamicFormFields.TypeOfCommitmentId]: number;
    [DynamicFormFields.TaxTypeId]: number;
  }>();
  const taxTypeId = watch("tax_type_id");
  const isCommitmentFieldShow = useMemo(() => {
    if (formFields?.tax_type_id?.show) {
      if (taxTypeId !== 5) return false;
    }
    return true;
  }, [formFields?.tax_type_id?.show, taxTypeId]);

  if (!isCommitmentFieldShow) return null;

  return (
    <SelectField
      control={control}
      name={DynamicFormFields.TypeOfCommitmentId}
      label="Вид обязательства"
      options={commitments}
      selectProps={{
        placeholder: "Вид обязательства",
        disabled: formFields?.type_of_commitment_id?.disabled,
        showSearch: true,
        optionFilterProp: "title",
        fieldNames: {
          label: "title",
          value: "id",
        },
      }}
      disabled={allDisabled}
    />
  );
};

export const CommitmentTypeField = observer(CommitmentTypeFieldComponent);
