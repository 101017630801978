import { getMoneyTransactions, addStatementsFile, IStatementsListMeta } from "api";
import { subMonths, subDays } from "date-fns";
import { makeAutoObservable, toJS } from "mobx";
import { PayTypeBS, SortParamBS } from "./types";
import { proccessDataBS } from "./utils";
import { SortOrder } from "assets/types";
import { toggleArrElem } from "assets/utils";
import { Value } from "react-calendar/src/shared/types";
import { RootStore } from "../../store/rootStore";
import dayjs from "dayjs";

export class BankStatementsStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  dataBS: any[] = [];
  contragentsBS: string[] = [];
  isLoadingBS: boolean = false;
  isUploading: boolean = false;
  detailsId: number | null = null;

  periodStartBS: Value = null;
  periodEndBS: Value = null;
  pickedPayTypesBS: PayTypeBS[] | string[] = []; // фильтры
  pickedOperTypesBS: string[] = [];
  pickedContragentsBS: string[] = [];
  searchTextBS: string = "";
  sortParamBS: "date" | "docId" | "contragent" | null = null;
  sortOrderBS: SortOrder = "asc";
  file: any = null;

  currentPage: number = 1;
  maxOperationsNumber: number = 10;
  //!Новые
  //#region Новые данные
  parserInfo: IStatementsListMeta | null = null;

  isUploadingError: boolean = false;
  uploadedInfo: { error: number; success: number } | null = null;

  setUploadedInfo = (info: typeof this.uploadedInfo) => {
    this.uploadedInfo = info;
  };

  setIsUploadingError = (val: boolean) => {
    this.isUploadingError = val;
  };
  //#endregion

  setCurrentPage = (val: number) => {
    this.currentPage = val;
  };

  setMaxOperationsNumber = (val: number) => {
    this.maxOperationsNumber = val;
  };

  get proccessedDataBS(): any[] {
    this.setCurrentPage(1);
    return proccessDataBS(
      toJS(this.dataBS),
      this.pickedPayTypesBS,
      this.pickedOperTypesBS,
      this.pickedContragentsBS,
      this.searchTextBS,
      this.sortParamBS,
      this.sortOrderBS
    );
  }
  get isFilterDefault(): boolean {
    return this.pickedPayTypesBS.length === 0 &&
      this.pickedOperTypesBS.length === 0 &&
      this.pickedContragentsBS.length === 0
      ? true
      : false;
  }
  get detailsData(): any {
    if (this.detailsId !== null) {
      let pickedStatement = this.dataBS.find(st => st.id === this.detailsId);
      return pickedStatement
        ? {
            date: pickedStatement.docdate,
            startBal: pickedStatement.startbalance,
            finalBal: pickedStatement.finalbalance,
            turnover: pickedStatement.turnover,
          }
        : null;
    } else return null;
  }

  setFile = (val: any) => {
    console.log("setFile");
    this.file = val;
  };
  setDetailsId = (id: number | null) => {
    this.detailsId = id;
  };
  setIsLoadingBS = (bool: boolean) => {
    this.isLoadingBS = bool;
  };
  setUploading = (bool: boolean) => {
    this.isUploading = bool;
  };
  setDataBS = (arr: any[]) => {
    this.dataBS = arr;
  };
  setContragentsBS = (arr: any[]) => {
    this.contragentsBS = arr;
  };
  setPeriodStartBS = (date: Value) => {
    this.periodStartBS = date;
  };
  setPeriodEndBS = (date: Value) => {
    this.periodEndBS = date;
  };
  setPickedPayTypesBS = (arr: any[]) => {
    this.pickedPayTypesBS = arr;
  };
  setPickedOperTypesBS = (arr: any[]) => {
    this.pickedOperTypesBS = arr;
  };
  setPickedContragentsBS = (arr: any[]) => {
    this.pickedContragentsBS = arr;
  };
  setSearchTextBS = (text: string) => {
    this.searchTextBS = text;
  };
  setSortParamBS = (param: SortParamBS) => {
    this.sortParamBS = param;
  };
  setSortOrderBS = (str: SortOrder) => {
    this.sortOrderBS = str;
  };

  resetPaytype = () => {
    this.pickedPayTypesBS = [];
  };
  resetContragent = () => {
    this.pickedContragentsBS = [];
  };
  clearSearchTextBS = () => {
    this.searchTextBS = "";
  };
  resetPeriod = () => {
    this.periodStartBS = subMonths(new Date(), 1);
    this.periodEndBS = subDays(new Date(), 1);
    this.getInitialDataBS();
  };
  resetFiltersBS = () => {
    this.pickedPayTypesBS = [];
    this.pickedOperTypesBS = [];
    this.pickedContragentsBS = [];
  };
  handlePeriodChangeBS = (period: Value) => {
    if (!period || !Array.isArray(period)) return;
    this.setDetailsId(null);
    this.setPeriodStartBS(period[0]);
    this.setPeriodEndBS(period[1]);
    this.getInitialDataBS();
  };
  handleColumnTitleClickBS = (param: SortParamBS) => {
    if (this.sortParamBS === param) {
      this.toggleSortOrderBS();
    } else {
      this.setSortOrderBS("asc");
      this.setSortParamBS(param);
    }
  };
  toggleSortOrderBS = () => {
    if (this.sortOrderBS === "asc") {
      this.setSortOrderBS("desc");
    } else {
      this.setSortOrderBS("asc");
    }
  };
  togglePayTypeBS = (payType: string) => {
    this.pickedPayTypesBS = toggleArrElem(payType, this.pickedPayTypesBS);
  };
  toggleOperTypeBS = (operType: string) => {
    this.pickedOperTypesBS = toggleArrElem(operType, this.pickedPayTypesBS);
  };
  toggleContragentBS = (contragent: string) => {
    this.pickedContragentsBS = toggleArrElem(contragent, this.pickedContragentsBS);
  };

  //!Обновлен
  getInitialDataBS = async (args?: { page?: number; per_page?: number }) => {
    this.setIsLoadingBS(true);

    const response = await getMoneyTransactions({
      page: args?.page ?? this.currentPage,
      per_page: args?.per_page ?? this.maxOperationsNumber,
      date_from: this.periodStartBS
        ? dayjs(this.periodStartBS.toString()).format("YYYY-MM-DD")
        : undefined,
      date_to: this.periodEndBS
        ? dayjs(this.periodEndBS.toString()).format("YYYY-MM-DD")
        : undefined,
    });
    if (!response) return;
    this.setDataBS(response.data);
    this.parserInfo = response.meta;
    // const response: any = await fetchInitialDataBS(this.periodStartBS, this.periodEndBS);

    // if (response !== null && response.statements.length > 0) {
    //   this.setDataBS(response.statements);

    //   this.setContragentsBS(response.contragents);
    // }
    this.setIsLoadingBS(false);
  };
  uploadStatements = async () => {
    if (this.file !== null) {
      this.setUploading(true);
      const formData = new FormData();
      formData.append("file", this.file);
      const response = await addStatementsFile(formData);
      if (!response) {
        this.setUploading(false);
        this.setIsUploadingError(true);
        return;
      }

      this.setUploadedInfo(response);
      this.getInitialDataBS();
      this.setFile(null);

      this.setUploading(false);
    }
  };
}
