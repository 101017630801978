import {
  ITransactionCashFlowArticle,
  ITransactionOperationType,
  ITransactionPayType,
} from "../types";

export interface FormValues {
  date_delivery: Date;
  number: string;
  spot_bank_account_number: string;
  pay_type_id: ITransactionPayType["id"];
  operation_type_id: ITransactionOperationType["id"];
  cash_flow_article_id: ITransactionCashFlowArticle["id"];
  contragent_id: number;
  contragent_inn: number;
  deal_title: string;
  amount: number;
  commission: number;
}

export enum DynamicFormFields {
  CashFlowArticleId = "cash_flow_article_id",
  PnLArticleId = "pnl_article_id",
  ContragentId = "contragent_id",
  Commission = "commission",
  TaxRate = "tax_rate",
  TaxAmount = "tax_amount",
  TaxTypeId = "tax_type_id",
  TypeOfCommitmentId = "type_of_commitment_id",
  Period = "period",
}

export enum FormFields {
  CashFlowArticleId = "cash_flow_article_id",
  PnLArticleId = "pnl_article_id",
  ContragentId = "contragent_id",
  Commission = "commission",
  TaxRate = "tax_rate",
  TaxAmount = "tax_amount",
  TaxTypeId = "tax_type_id",
  TypeOfCommitmentId = "type_of_commitment_id",
  Period = "period",
}

export interface IField {
  show: boolean;
  disabled: boolean;
  default?: any;
}

type IFieldsInOperation = {
  [key in DynamicFormFields]: IField;
};

export type IFieldsByOperation = Partial<IFieldsInOperation>;
