import * as yup from "yup";
import { DynamicFormFields, IFieldsByOperation } from "../types";
import { ValidationMessages, ValidationTypes } from "assets/utils/validationMessages";

export const createSchema = (fields: IFieldsByOperation | null) => {
  let shape: Record<string, yup.AnySchema> = {};
  if (fields)
    shape = Object.entries(fields).reduce((acc, [field, val]) => {
      switch (field) {
        case DynamicFormFields.CashFlowArticleId:
          acc[DynamicFormFields.CashFlowArticleId] = yup
            .number()
            .typeError(ValidationMessages.get(ValidationTypes.required) as string)
            .required(ValidationMessages.get(ValidationTypes.required));
          break;
        case DynamicFormFields.Commission:
          acc[DynamicFormFields.Commission] = yup
            .number()
            .typeError(ValidationMessages.get(ValidationTypes.number) as string)
            .required(ValidationMessages.get(ValidationTypes.required))
            .min(0, () => ValidationMessages.get(ValidationTypes.min) + ` 0,00`);
          break;
        case DynamicFormFields.ContragentId:
          acc[DynamicFormFields.ContragentId] = yup
            .number()
            .typeError(ValidationMessages.get(ValidationTypes.required) as string)
            .required(ValidationMessages.get(ValidationTypes.required));
          break;
        case DynamicFormFields.Period:
          acc[DynamicFormFields.Period] = yup
            .date()
            .required(ValidationMessages.get(ValidationTypes.required));
          break;
        case DynamicFormFields.PnLArticleId:
          acc[DynamicFormFields.PnLArticleId] = yup
            .number()
            .typeError(ValidationMessages.get(ValidationTypes.required) as string)
            .required(ValidationMessages.get(ValidationTypes.required));
          break;
        case DynamicFormFields.TaxAmount:
          acc[DynamicFormFields.TaxAmount] = yup
            .number()
            .typeError(ValidationMessages.get(ValidationTypes.number) as string)
            .required(ValidationMessages.get(ValidationTypes.required))
            .min(0, () => ValidationMessages.get(ValidationTypes.min) + ` 0,00`);
          break;
        case DynamicFormFields.TaxRate:
          acc[DynamicFormFields.TaxRate] = yup
            .number()
            .typeError(ValidationMessages.get(ValidationTypes.number) as string)
            .required(ValidationMessages.get(ValidationTypes.required))
            .min(0, ({ min }) => ValidationMessages.get(ValidationTypes.min) + ` ${min}`)
            .max(100, ({ max }) => ValidationMessages.get(ValidationTypes.max) + ` ${max}`);
          break;
        case DynamicFormFields.TaxTypeId:
          acc[DynamicFormFields.TaxTypeId] = yup
            .number()
            .typeError(ValidationMessages.get(ValidationTypes.required) as string)
            .required(ValidationMessages.get(ValidationTypes.required));
          break;
        case DynamicFormFields.TypeOfCommitmentId:
          acc[DynamicFormFields.TypeOfCommitmentId] = yup
            .number()
            .typeError(ValidationMessages.get(ValidationTypes.required) as string)
            .required(ValidationMessages.get(ValidationTypes.required));
          break;
        default:
          acc[field] = yup.string();
      }

      acc[field] = acc[field].when((_, schema) => {
        if (!val.show) return schema.notRequired();
        return schema;
      });
      return acc;
    }, {} as Record<string, yup.AnySchema>);

  shape["date_delivery"] = yup
    .date()
    .max(new Date(), "Дата проведения не может быть больше сегодняшней даты")
    .required(ValidationMessages.get(ValidationTypes.required));
  shape["number"] = yup
    .string()
    .min(3, ({ min }) => ValidationMessages.get(ValidationTypes.min) + ` ${min} символа`)
    .max(50, ({ max }) => ValidationMessages.get(ValidationTypes.min) + ` ${max} символов`)
    .matches(/^[0-9]+$/, "Допустимы только буквы и цифры")
    .required(ValidationMessages.get(ValidationTypes.required));
  shape["pay_type_id"] = yup
    .number()
    .typeError(ValidationMessages.get(ValidationTypes.required) as string)
    .required(ValidationMessages.get(ValidationTypes.required));
  shape["operation_type_id"] = yup
    .number()
    .typeError(ValidationMessages.get(ValidationTypes.required) as string)
    .required(ValidationMessages.get(ValidationTypes.required));
  return yup.object().shape(shape);
};
