import { observer } from "mobx-react-lite";
import { FC, useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useStore } from "store";
import { EInputStyleVariant, Input } from "ui-new/input";
import SelectField from "ui-new/select";
import { Modal } from "widgets/modal";
import { ModalButtons } from "widgets/modal/buttons";
import cls from "./styles.module.scss";
import { toJS } from "mobx";
import * as yup from "yup";
import { ValidationMessages, ValidationTypes } from "assets/utils/validationMessages";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  ITransactionCashFlowArticle,
  ITransactionOperationType,
} from "pages/bank-statements/types";

const schema = yup.object({
  contragent_id: yup
    .number()
    .typeError(ValidationMessages.get(ValidationTypes.required) as string)
    .required(ValidationMessages.get(ValidationTypes.required)),
  type: yup
    .string()
    .oneOf(["prepayment", "postpayment"])
    .required(ValidationMessages.get(ValidationTypes.required)),
  days: yup
    .number()
    .typeError("Введите корректное значение от 0 до 999")
    .min(0, "Введите корректное значение")
    .max(999, "Не более 999 дней")
    .required(ValidationMessages.get(ValidationTypes.required))
    .when("type", (deps, schema) => {
      const type = deps[0];
      if (type === "prepayment") {
        return schema.notRequired();
      }

      return schema;
    }),
});

interface FormValues {
  contragent_id: number;
  type: "postpayment" | "prepayment";
  days: number;
  non_cash_operation_type_id?: ITransactionOperationType["id"];
  cash_operation_type_id?: ITransactionOperationType["id"];
  cash_flow_article_id?: ITransactionCashFlowArticle["id"];
}

const handleInput = (event: React.FormEvent<HTMLInputElement>) => {
  const target = event.target as HTMLInputElement;
  // Используем setTimeout, чтобы обеспечить корректное обновление
  setTimeout(() => {
    // Заменяем точки, запятые и "e"
    target.value = target.value.replace(/[.,e]/g, "");
  }, 0);
};

interface AddContractProps {
  onClose: () => void;
}

const types = [
  { value: "prepayment", label: "Предоплата" },
  { value: "postpayment", label: "Отсрочка платежа" },
];

const AddContractModalComponent: FC<AddContractProps> = ({ onClose }) => {
  const {
    RootStore: {
      ContractsStore: {
        contragents,
        updateDealConfirm,
        fetchGetOperationTypes,
        fetchGetFlowArticles,
        cashFlowArticles,
        operationTypes,
        selectedDeal,
        setSelectedDeal,
      },
    },
  } = useStore();
  const { handleSubmit, control, watch, setValue } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: toJS(selectedDeal) ?? {},
  });

  useEffect(() => {
    fetchGetFlowArticles();
    fetchGetOperationTypes();
    return () => setSelectedDeal();
  }, [fetchGetFlowArticles, fetchGetOperationTypes]);

  const contragentsData = useMemo(() => {
    if (selectedDeal)
      return [{ title: selectedDeal.contragent_title, id: selectedDeal.contragent_id }];
    return toJS(contragents);
  }, [contragents, selectedDeal]);

  const [cashOperationTypes, nonCashOperationTypes] = useMemo(() => {
    const cashOperationTypes: ITransactionOperationType[] = [];
    const nonCashOperationTypes: ITransactionOperationType[] = [];
    operationTypes.forEach(opType => {
      if (opType.money_transaction_type_code === "NON_CASH") {
        nonCashOperationTypes.push(opType);
        return;
      }
      if (opType.money_transaction_type_code === "CASH") {
        cashOperationTypes.push(opType);
      }
    });
    return [cashOperationTypes, nonCashOperationTypes];
  }, [operationTypes]);

  const onSubmit = useCallback(
    (args: FormValues) => updateDealConfirm({ ...args, days: args.days ?? 0 }, onClose),
    [onClose, updateDealConfirm]
  );

  const type = watch("type");

  useEffect(() => {
    if (type === "prepayment") setValue("days", 0);
  }, [type]);

  return (
    <Modal
      title={selectedDeal ? "Редактировать договор" : "Добавить договор"}
      onClose={onClose}
      maxWidth="550px"
      minWidth="480px"
    >
      <div className={cls["modal-container"]}>
        <SelectField
          control={control}
          name="contragent_id"
          label={"Поставщик"}
          selectProps={{
            showSearch: true,
            optionFilterProp: "title",
            placeholder: "Выбери поставщика",
            fieldNames: { value: "id", label: "title" },
            disabled: !!selectedDeal,
          }}
          options={contragentsData}
        />
        <SelectField
          control={control}
          name="type"
          label={"Условие оплаты"}
          selectProps={{
            placeholder: "Выбери условие оплаты по договору",
          }}
          options={types}
        />
        <Input
          variant={EInputStyleVariant.basicInput}
          disabled={type === "prepayment"}
          control={control}
          name="days"
          type="number"
          placeholder="Введи количество дней отсрочки платежа"
          label="Количество дней отсрочки"
          inputProps={{ onInput: handleInput }}
        />
        <div className={cls["field-container"]}>
          <label className={cls["field-label"]}>Тип операции (расчетный счет)</label>
          <span className={cls["field-description"]}>
            Выбранный тип будет автоматически подставляться в операции по расчетному счету с данным
            поставщиком (банковские выписки)
          </span>
          <SelectField
            control={control}
            name="non_cash_operation_type_id"
            options={nonCashOperationTypes}
            selectProps={{
              placeholder: "Выбери тип операции для р/с",
              fieldNames: { label: "title", value: "id" },
              showSearch: true,
              optionFilterProp: "title",
              allowClear: true,
            }}
          />
        </div>
        <div className={cls["field-container"]}>
          <label className={cls["field-label"]}>Тип операции (касса)</label>
          <span className={cls["field-description"]}>
            Выбранный тип будет автоматически подставляться в операции по кассе с данным поставщиком
            (кассовые ордера)
          </span>
          <SelectField
            control={control}
            name="cash_operation_type_id"
            options={cashOperationTypes}
            selectProps={{
              placeholder: "Выбери тип операции для кассы",
              fieldNames: { label: "title", value: "id" },
              showSearch: true,
              optionFilterProp: "title",
              allowClear: true,
            }}
          />
        </div>
        <div className={cls["field-container"]}>
          <label className={cls["field-label"]}>Статья ДДС</label>
          <span className={cls["field-description"]}>
            Выбранная статья ДДС будет автоматически подставляться во все денежные операции с данным
            поставщиком.
          </span>
          <SelectField
            control={control}
            name="cash_flow_article_id"
            options={cashFlowArticles}
            selectProps={{
              placeholder: "Выбери условие оплаты по договору",
              fieldNames: { label: "title", value: "id" },
              showSearch: true,
              optionFilterProp: "title",
              allowClear: true,
            }}
          />
        </div>
        <div className={cls["modal-buttons"]}>
          <ModalButtons
            onPrimaryButtonClick={handleSubmit(onSubmit)}
            primaryButtonLabel={"Сохранить"}
            onSecondaryButtonClick={onClose}
            secondaryButtonLabel={"Отменить"}
          />
        </div>
      </div>
    </Modal>
  );
};

export const AddContractModal = observer(AddContractModalComponent);
