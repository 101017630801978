import { makeAutoObservable } from "mobx";
import { RootStore } from "../../store/rootStore";
import { getContragents, getDeals, IDealUpdateProps, updateDeal } from "api/contracts";
import { IContragent, IDeal } from "./types";
import { showNotificationModal } from "ui-new/alert";
import { showConfirmModal } from "ui-new/alert/confirm";
import { getCashFlowArticles, getOperationTypes } from "api";
import {
  ITransactionCashFlowArticle,
  ITransactionOperationType,
} from "pages/bank-statements/types";

export class ContractsStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  deals: IDeal[] = [];
  contragents: IContragent[] = [];
  operationTypes: ITransactionOperationType[] = [];
  cashFlowArticles: ITransactionCashFlowArticle[] = [];
  selectedDeal: IDeal | null = null;

  setSelectedDeal = (deal?: IDeal) => {
    this.selectedDeal = deal ?? null;
  };

  fetchGetDeals = async () => {
    const response = await getDeals();

    if (!response) return;

    this.deals = response;
  };

  fetchGetContragents = async () => {
    const response = await getContragents();

    if (!response) return;

    this.contragents = response;
  };

  updateDealConfirm = (args: IDealUpdateProps, onConfirm: () => void) => {
    const isEdit = !!this.selectedDeal;

    showConfirmModal({
      title: isEdit ? "Изменить договор ?" : "Добавить новый договор?",
      messages: [
        "Новые условия оплаты будут применены ко всем взаиморасчетам по данному договору.",
      ],
      confirmButtonLabel: isEdit ? "Да, изменить" : "Да, добавить",
      onConfirm: () => this.fetchUpdateDeal(args).then(resp => resp && onConfirm()),
      minWidth: "397px",
      maxWidth: "397px",
    });
  };

  fetchUpdateDeal = async (args: IDealUpdateProps) => {
    const response = await updateDeal(args);

    if (!response) return null;

    showNotificationModal({
      title: this.selectedDeal ? "Договор изменен" : "Договор добавлен",
      type: "success",
    });
    this.fetchGetDeals();
    this.fetchGetContragents();

    return true;
  };

  fetchGetOperationTypes = async () => {
    const response = await getOperationTypes();
    if (!response) return;
    this.operationTypes = response;
  };

  fetchGetFlowArticles = async () => {
    const response = await getCashFlowArticles();
    if (!response) return;
    this.cashFlowArticles = response;
  };
}
