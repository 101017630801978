import { useNavigate } from "react-router";
import { InPayIcon, OutPayIcon } from "../icons";
import { observer } from "mobx-react-lite";
import { IMoneyTransaction } from "../types";
import dayjs from "dayjs";
import { addSpaces } from "assets/utils";
import { Status } from "./status";

export const TableRowBS = observer(
  ({ rowData, index }: { rowData: IMoneyTransaction; index: number }) => {
    const navigate = useNavigate();

    function editStatement(id: number) {
      navigate(`/main/statements/edit#${id}`);
    }

    return (
      <div
        className="statements-table__body-row cursor-pointer"
        key={index}
        data-testid={"bs-elem"}
      >
        <div className="statements-table__body-element f144">
          {dayjs(rowData.date).format("DD.MM.YYYY")}
        </div>
        <div
          className="statements-table__body-element f144"
          onClick={() => editStatement(rowData.id)}
        >
          {rowData.deal_title}
        </div>
        <div
          className="statements-table__body-element f284"
          onClick={() => editStatement(rowData.id)}
          title={rowData.contragent_title ?? ""}
        >
          <p>{rowData.contragent_title}</p>
        </div>
        <div
          className="statements-table__body-element f144"
          onClick={() => editStatement(rowData.id)}
        >
          {rowData.pay_type_title === "Поступление" && <OutPayIcon />}
          {rowData.pay_type_title === "Списание" && <InPayIcon />}
          {rowData?.amount && addSpaces(rowData.amount?.toFixed(2))}
        </div>
        <div
          className="statements-table__body-element f120"
          onClick={() => editStatement(rowData.id)}
          style={{ justifyContent: "center" }}
        >
          {rowData.type_title}
        </div>
        <div
          className="statements-table__body-element f290"
          onClick={() => editStatement(rowData.id)}
        >
          {rowData.operation_type_title}
        </div>
        <div
          className="statements-table__body-element f154 "
          onClick={() => editStatement(rowData.id)}
        >
          <Status status={rowData.status_title} />
        </div>
      </div>
    );
  }
);
