import { FC, useMemo } from "react";
import { useStore } from "store";
import SelectField from "ui-new/select";
import { DynamicFormFields } from "../../types";
import { useFormContext } from "react-hook-form";
import { EInputStyleVariant, Input } from "ui-new/input";
import { observer } from "mobx-react-lite";

interface TaxTypeIdPeriodCommitmentFieldsProps {
  allDisabled: boolean;
}

const TaxTypeIdPeriodCommitmentFieldsComponent: FC<TaxTypeIdPeriodCommitmentFieldsProps> = ({
  allDisabled,
}) => {
  const {
    RootStore: {
      EditMoneyTransactionStore: { formFields, taxes, commitments },
    },
  } = useStore();

  const { control, watch } = useFormContext<{
    [DynamicFormFields.TaxTypeId]: number;
    [DynamicFormFields.TypeOfCommitmentId]: number;
    [DynamicFormFields.Period]: Date;
  }>();

  const taxTypeId = watch("tax_type_id");
  const isSupportFieldsShow = useMemo(() => {
    if (formFields?.tax_type_id?.show) {
      if (taxTypeId === 5) return false;
    }
    return true;
  }, [formFields?.tax_type_id?.show, taxTypeId]);

  return (
    <>
      {formFields?.tax_type_id?.show && (
        <SelectField
          label="Налог"
          control={control}
          name={DynamicFormFields.TaxTypeId}
          selectProps={{
            placeholder: "Выберите налог",
            disabled: formFields?.tax_type_id?.disabled,
            showSearch: true,
            optionFilterProp: "title",
            fieldNames: {
              label: "title",
              value: "id",
            },
          }}
          options={taxes}
          disabled={allDisabled}
        />
      )}
      {isSupportFieldsShow && formFields?.type_of_commitment_id?.show && (
        <SelectField
          control={control}
          name={DynamicFormFields.TypeOfCommitmentId}
          label="Вид обязательства"
          options={commitments}
          selectProps={{
            placeholder: "Вид обязательства",
            disabled: formFields?.type_of_commitment_id?.disabled,
            showSearch: true,
            optionFilterProp: "title",
            fieldNames: {
              label: "title",
              value: "id",
            },
          }}
          disabled={allDisabled}
        />
      )}
      {isSupportFieldsShow && formFields?.period?.show && (
        <Input
          label='Период "За"'
          control={control}
          variant={EInputStyleVariant.basicInput}
          type="date"
          name={DynamicFormFields.Period}
          disabled={allDisabled}
          inputProps={{ disabled: formFields?.period?.disabled }}
        />
      )}
    </>
  );
};

export const TaxTypeIdPeriodCommitmentFields = observer(TaxTypeIdPeriodCommitmentFieldsComponent);
