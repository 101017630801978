import { makeAutoObservable, toJS } from "mobx";
import { RootStore } from "../../../store/rootStore";
import {
  ICommitment,
  IContragent,
  IMoneyTransaction,
  IPnLArticle,
  ITax,
  ITransactionCashFlowArticle,
  ITransactionOperationType,
  ITransactionPayType,
  ITransactionStatus,
  ITransactionType,
} from "../types";
import {
  deleteMoneyTransaction,
  getCommitments,
  getMoneyTransaction,
  getPnLArticles,
  getTaxes,
  getTransactionCashFlowArticles,
  getTransactionContragents,
  getTransactionOperationTypes,
  getTransactionPaymentTypes,
  getTransactionStatuses,
  getTransactionTypes,
  IContragentMetaProps,
  updateMoneyTransaction,
} from "api";
import { showConfirmModal } from "ui-new/alert/confirm";
import { IFieldsByOperation } from "./types";
import { getEmployees } from "api/accessRights";
import { IUser } from "pages/salary-fund/types";

export class EditMoneyTransactionStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  transaction: IMoneyTransaction | null = null;
  transactionIsLoading = false;

  setTransaction = (t: IMoneyTransaction | null) => {
    this.transaction = t;
  };

  statuses: ITransactionStatus[] = [];
  types: ITransactionType[] = [];
  payTypes: ITransactionPayType[] = [];
  operationTypes: ITransactionOperationType[] = [];
  cashFlowArticles: ITransactionCashFlowArticle[] = [];
  pnlArticles: IPnLArticle[] = [];
  commitments: ICommitment[] = [];
  taxes: ITax[] = [];
  employees: IUser[] = [];
  contragents: IContragent[] = [];
  contragentsIsLoading = false;
  contragentsMeta: IContragentMetaProps | null = null;
  formFields: IFieldsByOperation | null = null;

  setFormFields = (fields: typeof this.formFields) => {
    this.formFields = fields;
  };

  fetchGetTransaction = async (transactionId: IMoneyTransaction["id"]) => {
    this.transactionIsLoading = true;
    const response = await getMoneyTransaction(transactionId);
    this.transactionIsLoading = false;
    if (!response) return;

    this.setTransaction(response);
  };

  updateTransactionConfirm = (body: Partial<IMoneyTransaction>, onConfirm?: () => void) => {
    showConfirmModal({
      title: "Обновить платежное поручение?",
      confirmButtonLabel: "Обновить",
      onConfirm: () => this.fetchUpdateTransaction(body).then(onConfirm),
      minWidth: "350px",
    });
  };

  fetchUpdateTransaction = async (body: Partial<IMoneyTransaction>) => {
    this.transactionIsLoading = true;
    const response = await updateMoneyTransaction(body);
    if (!response) {
      this.transactionIsLoading = false;
      return;
    }
    this.transaction = response;
    this.transactionIsLoading = false;
  };

  deleteTransactionConfirm = (onConfirm?: () => void) => {
    showConfirmModal({
      title: "Удалить платежное поручение?",
      confirmButtonLabel: "Удалить",
      onConfirm: () => this.fetchDeleteTransaction().then(onConfirm),
      minWidth: "350px",
    });
  };

  fetchDeleteTransaction = async () => {
    if (!this.transaction) return;
    const response = await deleteMoneyTransaction(this.transaction.id);
    if (!response) return;
    this.setTransaction(response);
  };

  fetchGetTransactionStatuses = async () => {
    const response = await getTransactionStatuses();
    if (!response) return;

    this.statuses = response;
  };

  fetchGetTransactionTypes = async () => {
    const response = await getTransactionTypes();
    if (!response) return;

    this.types = response;
  };

  fetchGetTransactionPayTypes = async () => {
    const response = await getTransactionPaymentTypes();
    if (!response) return;
    this.payTypes = response;
  };

  fetchGetTransactionOperationTypes = async () => {
    const response = await getTransactionOperationTypes();
    if (!response) return;
    this.operationTypes = response;
  };

  fetchGetTransactionCashFlowArticles = async () => {
    const response = await getTransactionCashFlowArticles();
    if (!response) return;
    this.cashFlowArticles = response;
  };
  fetchGetContragents = async (searchValue?: string, page?: number) => {
    this.contragentsIsLoading = true;
    const response = await getTransactionContragents({
      per_page: 50,
      search: searchValue,
      page: page ?? this.contragentsMeta?.current_page,
    });
    if (!response) {
      this.contragentsIsLoading = false;
      return;
    }
    this.contragents =
      response.meta.current_page === 1
        ? response.data
        : [...toJS(this.contragents), ...response.data];
    this.contragentsMeta = response.meta;
    this.contragentsIsLoading = false;
  };

  fetchGetEmployees = async () => {
    const response = await getEmployees();
    if (!response) return;
    this.employees = response;
  };

  fetchGetPnLArticles = async () => {
    const response = await getPnLArticles();
    if (!response) return;
    this.pnlArticles = response;
  };

  fetchGetCommitments = async () => {
    const response = await getCommitments();
    if (!response) return;
    this.commitments = response;
  };

  fetchGetTaxes = async () => {
    const response = await getTaxes();
    if (!response) return;
    this.taxes = response;
  };

  fetchGetTransactionGuide = async () => {
    this.fetchGetTransactionCashFlowArticles();
    this.fetchGetTransactionOperationTypes();
    this.fetchGetTransactionPayTypes();
    this.fetchGetTransactionStatuses();
    this.fetchGetTransactionTypes();
    this.fetchGetPnLArticles();
    this.fetchGetCommitments();
    this.fetchGetTaxes();
  };
}
