import { IContragentLoan, IInvoice } from "pages/loans-contragents/types";
import { IBaseResponse } from "./api";
import { api } from "./api-new";
import { onError } from "./schedulePlanning";
import { IContragent } from "pages/bank-statements/types";

export interface IGetContragentsLoansResponse {
  byInvoices: IInvoice[];
  byContragents: IContragentLoan[];
}

export async function getContragentsLoans(date: string) {
  try {
    const response = await api.post<IBaseResponse<IGetContragentsLoansResponse>>(
      "loans/contragents/list",
      { date }
    );
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}
