import { observer } from "mobx-react-lite";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useStore } from "store";
import { EInputStyleVariant, Input } from "ui-new/input";
import SelectField from "ui-new/select";
import { DynamicFormFields } from "../../types";
import { toJS } from "mobx";
import { debounce } from "lodash";
import { Spin } from "antd";

const BUFFER_DISTANCE = 500;

interface ContragentProps {
  allDisabled: boolean;
}

const ContragentComponent: FC<ContragentProps> = ({ allDisabled }) => {
  const {
    RootStore: {
      EditMoneyTransactionStore: {
        transaction,
        contragents,
        fetchGetContragents,
        formFields,
        contragentsIsLoading,
        contragentsMeta,
        employees,
      },
    },
  } = useStore();
  const [searchTitle, setSearchTitle] = useState("");
  const debounceSearch = debounce((val: string) => {
    setSearchTitle(val);
    fetchGetContragents(val, 1);
  }, 300);

  const handleScroll = useCallback(
    (event: React.UIEvent<HTMLDivElement>) => {
      const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
      if (scrollHeight - scrollTop - clientHeight <= BUFFER_DISTANCE && !contragentsIsLoading) {
        fetchGetContragents(searchTitle, (contragentsMeta?.current_page ?? 0) + 1);
      }
    },
    [contragentsIsLoading, contragentsMeta?.current_page, fetchGetContragents, searchTitle]
  );

  const { control, watch, setValue, resetField } = useFormContext<{
    [DynamicFormFields.ContragentId]: number;
    contragent_inn: number;
    deal_title: string;
  }>();

  const contragentId = watch("contragent_id");

  const employeesData = useMemo(() => {
    const employeesData = toJS(employees).map(emp => ({
      value: emp.id,
      label: `${emp.surname} ${emp.name}`,
    }));
    if (!transaction?.contragent_id) return employeesData;

    const haveEmployee = employees.some(e => e.id === transaction.contragent_id);
    if (haveEmployee) return employeesData;

    return [...employeesData, { value: transaction.id, label: transaction.contragent_title }];
  }, [employees, transaction]);

  const contragentsData = useMemo(() => {
    if (!transaction?.contragent_id) return contragents;

    const haveContragent = contragents.some(c => c.id === transaction.contragent_id);
    if (haveContragent) return contragents;

    return [
      ...toJS(contragents),
      {
        id: transaction.contragent_id,
        title: transaction.contragent_title ?? "",
        inn: transaction.contragent_inn,
      },
    ];
  }, [contragents, transaction]);

  useEffect(() => {
    const contragent = contragents.find(c => c.id === contragentId);
    if (!contragent) {
      resetField("contragent_inn");
      resetField("deal_title");
      return;
    }

    setValue("contragent_inn", contragent.inn);
    setValue("deal_title", contragent.deal.title);
  }, [contragentId, contragents]);

  useEffect(() => {
    resetField("contragent_id");
  }, [transaction?.contragent_id]);

  const contragentFieldDisabled = useMemo(
    () => typeof transaction?.contragent_id === "number" || formFields?.contragent_id?.disabled,
    [transaction?.contragent_id]
  );

  return (
    <>
      {transaction?.contragent_type === "user" ? (
        <SelectField
          label={"Физ. лицо"}
          control={control}
          name={DynamicFormFields.ContragentId}
          options={employeesData}
          selectProps={{
            disabled: contragentFieldDisabled,
            showSearch: true,
            fieldNames: {
              label: "label",
              value: "value",
            },
          }}
          disabled={allDisabled}
        />
      ) : (
        <SelectField
          label={"Контрагент"}
          control={control}
          name={DynamicFormFields.ContragentId}
          options={contragentsData}
          selectProps={{
            disabled: contragentFieldDisabled,
            showSearch: true,
            optionFilterProp: "title",
            onSearch: debounceSearch,
            onPopupScroll: handleScroll,
            loading: contragentsIsLoading,
            fieldNames: {
              label: "title",
              value: "id",
            },
            dropdownRender: menu => (
              <>
                {menu}
                {contragentsIsLoading && (
                  <div style={{ textAlign: "center", padding: "8px" }}>
                    <Spin size="small" />
                  </div>
                )}
              </>
            ),
          }}
          disabled={allDisabled}
        />
      )}
      <Input
        label="ИНН"
        control={control}
        variant={EInputStyleVariant.basicInput}
        type="text"
        name="contragent_inn"
        inputProps={{
          disabled: true,
        }}
      />
      <Input
        label="Номер договора"
        control={control}
        variant={EInputStyleVariant.basicInput}
        type="text"
        name="deal_title"
        inputProps={{
          disabled: true,
        }}
      />
    </>
  );
};

export const Contragent = observer(ContragentComponent);
