import { observer } from "mobx-react-lite";
import { FC } from "react";
import cls from "../styles.module.scss";
import { useNavigate } from "react-router";
import { BackButton, BucketIcon } from "../icons";
import { useStore } from "store";

interface TransactionHeaderProps {
  onSave: () => void;
}

const TransactionHeaderComponent: FC<TransactionHeaderProps> = ({ onSave }) => {
  const {
    RootStore: {
      EditMoneyTransactionStore: { transaction, deleteTransactionConfirm },
    },
  } = useStore();

  const navigate = useNavigate();

  const onDeleteHandler = () => {
    //TODO: После удаления нужно обновить транзакции
    deleteTransactionConfirm();
  };

  return (
    <div className={cls["header"]}>
      <div className={cls["breadcrumbs"]}>
        <p className={cls["grey"]} onClick={() => navigate("/main/statements/")}>
          Выписка из банка /
        </p>
        <p className={cls["black"]}>Платежное поручение</p>
      </div>
      <div className={cls["title-container"]}>
        <div className={cls["text"]}>
          <div className={cls["backlink"]} onClick={() => navigate("/main/statements/")}>
            <BackButton />
          </div>
          <h2 className={cls["title"]}>Платежное поручение</h2>
        </div>
        {transaction?.status_title !== "Удалено" && (
          <div className={cls["buttons-group"]}>
            <button className={cls["transaction-delete__button"]} onClick={onDeleteHandler}>
              <BucketIcon color={"#EB5757"} /> Удалить
            </button>
            <button className="button-secondary">Утвердить</button>
            <button className="button-primary" onClick={onSave}>
              Сохранить
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export const TransactionHeader = observer(TransactionHeaderComponent);
