import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { EInputStyleVariant, Input } from "ui-new/input";
import { DynamicFormFields } from "../../types";

interface CommissionFieldProps {
  allDisabled: boolean;
}

const CommissionFieldComponent: FC<CommissionFieldProps> = ({ allDisabled }) => {
  const { control } = useFormContext<{ [DynamicFormFields.Commission]: string }>();

  return (
    <Input
      label="Комиссия"
      control={control}
      variant={EInputStyleVariant.basicInput}
      type="number"
      name={DynamicFormFields.Commission}
      disabled={allDisabled}
      placeholder="Возьми из назначения комиссии"
    />
  );
};

export const CommissionField = CommissionFieldComponent;
